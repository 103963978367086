import React from "react";
import {
  variables,
  NavbarSecondary,
  NavItem,
  NavIcon,
  MoreLink,
  Icon,
  Navbar,
  Logo,
  ButtonTest,
} from "@website2018/da-dobsonville";
import Sticky from "react-stickynode";
import { Query } from '@apollo/client/react/components';
import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import SettingsProvider from '../../containers/Settings/SettingsProvider';
import { Link, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import Notice from "./Notice";

const styles = StyleSheet.create({
  searchWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    background: variables.colors.brandWhite,
    width: "100%",
  },
  search: {
    display: "flex",
    alignItems: "center",
    marginRight: "auto",
    marginLeft: "auto",
  },
  searchInput: {
    fontFamily: variables.fonts.sans,
    borderRadius: "0px",
    paddingTop: "5px",
    paddingBottom: "5px",
    border: "none",
    color: variables.colors.textBase,
    ":focus": {
      border: "none",
      boxShadow: "none",
    },
    "@media (min-width: 768px)": {
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search: false,
      term: "",
      showCondensed: false,
    };

    this.links = [
      { label: "Why the DA?", link: "/why-the-da", desktop: true },
      {
        label: "Values and Principles",
        link: "/why-the-da/values-and-principles",
        desktop: true,
      },
      { label: "Policies", link: "/why-the-da/policies", desktop: true },
      { label: "Where we Govern", link: "/where-we-govern", desktop: true },
      { label: "Our People", link: "/our-people", desktop: true },
      { label: "Newsroom", link: "/newsroom", desktop: true },
      { label: "Contact", link: "/contact", desktop: true },
      { label: "Campaigns", link: "/campaigns", desktop: false },
    ];
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        open: false,
      });
      window.scrollTo(0, 0);
    }
  }

  handleSearch = () => {
    this.props.history.push(`/newsroom?query=${this.state.term}`);
  };

  handleKeyDown = (event) => {
    switch (event.key) {
      case "Enter":
        this.handleSearch();
        break;

      case "Escape":
        this.nameInput.blur();
        break;
      default:
        break;
    }
  };

  handleOpenSearch = () => {
    this.setState({
      search: true,
      term: "",
    });
    setTimeout(() => {
      this.nameInput.focus();
    }, 200);
  };

  handleCloseSearch = () => {
    this.setState({
      search: false,
      term: "",
    });
  };

  clearSearch = () => {
    this.setState({
      term: "",
    });
  };

  handleChange = (event) => {
    const { value } = event.target;
    const term = value.toLowerCase();

    if (!value) {
      this.clearSearch();
      return;
    }

    this.setState({
      term,
      value,
    });
  };

  handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      this.setState({
        showCondensed: false,
      });
    } else {
      this.setState({
        showCondensed: false,
      });
    }
  };

  renderSearch() {
    const { colors, spacing } = variables;

    if (this.state.search) {
      return (
        <div className={`${css(styles.searchWrapper)}`}>
          <div className={`container`}>
            <div className={`${css(styles.search)}`}>
              <input
                className={`form-control ${css(styles.searchInput)}`}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
                ref={(input) => {
                  this.nameInput = input;
                }}
                id="search-box"
                placeholder="Search and press 'Enter'"
                value={this.state.term}
                type="text"
              />
              <NavIcon
                onPress={() => this.handleSearch()}
                className="search_form--submit"
                mb="none"
              >
                <Icon key="search" name="search" />
              </NavIcon>
              <NavIcon
                onPress={() => this.handleCloseSearch()}
                className="search_form--close"
                mb="none"
              >
                <Icon
                  name="close"
                  fs="20px"
                  color={colors.textBase}
                  mr={spacing.space1}
                />
              </NavIcon>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  renderHrefButton(navItem, button, link, text) {
    return (
      <NavItem {...navItem} title={text} href={link}>
        <ButtonTest {...button}>{text}</ButtonTest>
      </NavItem>
    );
  }

  renderToButton(navItem, button, link, text) {
    return (
      <NavItem {...navItem}>
        <Link to={link}>
          <ButtonTest {...button}>{text}</ButtonTest>
        </Link>
      </NavItem>
    );
  }

  renderSearchIcon(navItem, navIcon) {
    const { spacing } = variables;
    return (
      <NavItem {...navItem}>
        <NavIcon onPress={() => this.handleOpenSearch()} mb="none" {...navIcon}>
          <Icon name="search" mr={spacing.space0} ml={spacing.space0} />
        </NavIcon>
      </NavItem>
    );
  }

  renderSocialItem(title, icon, link, navProps, iconProps) {
    const { spacing, colors } = variables;
    const navItem = {
      color: colors.textLightO,
      colorHover: colors.white,
      fs: "18px",
      ...navProps,
    };
    const iconItem = {
      mr: spacing.space0,
      ml: spacing.space0,
      ...iconProps,
    };
    return (
      <NavItem
        {...navItem}
        target="_blank"
        title={`Link to ${title}`}
        href={link}
      >
        <Icon iconType='bootstrap' name={icon} {...iconItem} />
      </NavItem>
    );
  }

  openMenu = () => {
    this.setState((state) => ({
      open: !state.open,
    }));
  };

  render() {
    const { colors, spacing, fontWeight } = variables;
    const {
      settings: { social },
    } = this.context;

    return (
      <nav>
        {this.renderSearch()}

        <div className="mobileTop">
          <NavbarSecondary>
            <NavItem
              fs={"13px"}
              lh={"16px"}
              color={colors.brandWhite}
              mr={"auto"}
              ml={spacing.space0}
            >
              <MoreLink
                color={"white"}
                arrowDirection="left"
                lowercase
                weight={"regular"}
                tracking={"small"}
                size={"medium"}
                href={"https://www.da.org.za"}
              >
                Visit da.org.za
              </MoreLink>
            </NavItem>
            {this.renderSearchIcon(
              { color: colors.brandWhite, fs: "18px", ml: "auto" },
              { color: colors.textLightO }
            )}
            {social.facebook
              ? this.renderSocialItem(
                  "Facebook",
                  "facebook",
                  social.facebook,
                  {},
                  {}
                )
              : null}
            {social.instagram
              ? this.renderSocialItem(
                  "Instagram",
                  "instagram",
                  social.instagram,
                  {},
                  {}
                )
              : null}
            {social.tiktok
              ? this.renderSocialItem("Tiktok", "tiktok", social.tiktok, {}, {})
              : null}
            {social.twitter
              ? this.renderSocialItem(
                  "X (Twitter)",
                  "twitter-x",
                  social.twitter,
                  {},
                  {}
                )
              : null}
            {social.youtube
              ? this.renderSocialItem(
                  "YouTube",
                  "youtube",
                  social.youtube,
                  {},
                  {}
                )
              : null}
            {social.whatsapp
              ? this.renderSocialItem(
                  "WhatsApp",
                  "whatsapp",
                  social.whatsapp,
                  {},
                  {}
                )
              : null}
          </NavbarSecondary>
        </div>

        <div className="desktopTop">
          <NavbarSecondary>
            {this.renderSearchIcon(
              { ml: "auto", fs: "16px", lh: "16px" },
              { color: colors.brandWhite }
            )}
            <NavItem
              fs={"13px"}
              lh={"16px"}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/why-the-da`}>Why the DA</Link>
            </NavItem>
            <NavItem
              fs={"13px"}
              lh={"16px"}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/why-the-da/values-and-principles`}>
                Values and Principles
              </Link>
            </NavItem>
            <NavItem
              fs={"13px"}
              lh={"16px"}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/why-the-da/policies`}>Policies</Link>
            </NavItem>
            <NavItem
              fs={"13px"}
              lh={"16px"}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/our-people`}>Our People</Link>
            </NavItem>
            <NavItem
              fs={"13px"}
              lh={"16px"}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/newsroom`}>Newsroom</Link>
            </NavItem>
            <NavItem
              fs={"13px"}
              lh={"16px"}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/where-we-govern`}>Where we Govern</Link>
            </NavItem>
            <NavItem
              fs={"13px"}
              lh={"16px"}
              color={colors.brandWhite}
              fontWeight={fontWeight.medium}
            >
              <Link to={`/contact`}>Contact</Link>
            </NavItem>
          </NavbarSecondary>
        </div>

        <Sticky // top
          enabled={true}
          top={0}
          onStateChange={this.handleStateChange}
          innerZ="9999"
        >
          <div>
            <div className="desktopTop">
              <Navbar spacing={this.state.showCondensed ? "small" : "medium"}>
                <NavItem mr={"auto"} ml={spacing.space0} lh={"0"}>
                  <Link to="/">
                    <Logo
                      division={null}
                      size={this.state.showCondensed ? "87px" : "130px"}
                    />
                  </Link>
                </NavItem>
                {social.facebook
                  ? this.renderSocialItem(
                      "Facebook",
                      "facebook",
                      social.facebook,
                      { fs: "24px" },
                      {}
                    )
                  : null}
                {social.instagram
                  ? this.renderSocialItem(
                      "Instagram",
                      "instagram",
                      social.instagram,
                      { fs: "24px" },
                      {}
                    )
                  : null}
                {social.tiktok
                  ? this.renderSocialItem(
                      "Tiktok",
                      "tiktok",
                      social.tiktok,
                      { fs: "24px" },
                      {}
                    )
                  : null}
                {social.twitter
                  ? this.renderSocialItem(
                    "X (Twitter)",
                    "twitter-x",
                      social.twitter,
                      { fs: "24px" },
                      {}
                    )
                  : null}
                {social.youtube
                  ? this.renderSocialItem(
                      "YouTube",
                      "youtube",
                      social.youtube,
                      { fs: "24px" },
                      {}
                    )
                  : null}
                {social.whatsapp
                  ? this.renderSocialItem(
                      "WhatsApp",
                      "whatsapp",
                      social.whatsapp,
                      { fs: "24px" },
                      {}
                    )
                  : null}
                <NavItem
                  fontWeight={fontWeight.bold}
                  color={colors.textLightO}
                  colorHover={colors.textWhite}
                >
                  <Link to={`/campaigns`}>Campaigns</Link>
                </NavItem>
                {this.renderHrefButton(
                  {
                    fontWeight: fontWeight.bold,
                    color: colors.linkBlue,
                    mr: spacing.space0,
                  },
                  { color: "green" },
                  "https://donate.da.org.za/",
                  "Donate"
                )}
                {this.renderHrefButton(
                  {
                    fontWeight: fontWeight.bold,
                    color: colors.linkBlue,
                    mr: spacing.space0,
                  },
                  { color: "white", outline: true },
                  "https://www.da.org.za/get-involved",
                  "Get Involved"
                )}
              </Navbar>
            </div>

            <div className="mobileTop">
              <Navbar>
                <NavItem lh={"0"}>
                  <Link to="/">
                    <Logo size="80px" division={null} />
                  </Link>
                </NavItem>
                <NavItem
                  ml="auto"
                  fontWeight={fontWeight.bold}
                  color={colors.linkBlue}
                >
                  <ButtonTest
                    onClick={this.openMenu}
                    color="white"
                    outline
                    iconBefore={this.state.open ? "close" : "bars"}
                    size="small"
                  >
                    {this.state.open ? "Close" : "Menu"}
                  </ButtonTest>
                </NavItem>
              </Navbar>
            </div>

            {this.state.open ? (
              <div>
                <Navbar align="vertical" bgColor={"light"}>
                  {this.links.map((item) => (
                    <NavItem
                      fs={"13px"}
                      lh={"16px"}
                      fontWeight={fontWeight.medium}
                      mt={spacing.space1}
                      mb={spacing.space2}
                      color={colors.linkBlue}
                      ml="0"
                      mr="0"
                    >
                      <Link to={`${item.link}`}>{item.label}</Link>
                    </NavItem>
                  ))}
                  {this.renderHrefButton(
                    {
                      mt: spacing.space1,
                      mb: spacing.space1,
                      ml: spacing.space0,
                    },
                    { color: "green" },
                    "https://donate.da.org.za/",
                    "Donate"
                  )}
                  {this.renderHrefButton(
                    {
                      mt: spacing.space1,
                      mb: spacing.space1,
                      ml: spacing.space0,
                    },
                    { color: "blue", outline: true },
                    "https://www.da.org.za/get-involved",
                    "Get Involved"
                  )}
                </Navbar>
              </div>
            ) : null}
          </div>
        </Sticky>

        <Query query={OPTIONS_QUERY}>
          {({ loading, error, data }) => {
            if (error) return null;
            if (loading) return null;

            const {
              options: { notices },
            } = data;

            if (notices) {
              return <Notice {...notices} />;
            }
          }}
        </Query>
      </nav>
    );
  }
}

Header.contextTypes = {
  settings: PropTypes.shape(SettingsProvider.settingDefinition).isRequired,
};

export default withRouter(Header);

const OPTIONS_QUERY = gql`
  query getOptions {
    options {
      notices {
        notice_isActive
        notice_bg
        notice_color
        notice_button
        notices {
          notice_title
          notice_text
          notice_button {
            title
            url
            target
          }
        }
      }
    }
  }
`;
